<template>
  <div class="main-page">
    <Headers />
    <img @click="toggleBasketState" class="basket-icon" :class="{'close':basketState === true}" src="@/assets/icons/Basket-icon.svg" alt="">
    <main>
      <router-view :openBasket="toggleBasketState"></router-view>
    </main>
    <Basket :closeBasket="toggleBasketState" :basketState="basketState" />
    <Footer />
  </div>
</template>

<script>
import Headers from '/src/components/Header/Header';

export default {
  name: "MainRouting",
  data(){
    return{
      basketState:false,
    }
  },
  methods:{
    toggleBasketState(){
      this.basketState = !this.basketState
    }
  },
  components:{
    Headers,
    Footer:() => import("@/components/Footer/Footer.vue"),
    Basket:()=> import("@/components/Basket/Basket.vue"),
  },
}
</script>

<style scoped lang="scss">
@import "MainPage";
</style>